import React from 'react';

import { useTranslation } from '../../../../hooks';
import { T } from '../../../../constants';

// import logo from '../../../../assets/images/logo.svg';

interface WelcomeProps {

}

export const Welcome: React.FC<WelcomeProps> = () => {

  const t = useTranslation();

  // /*{t(T.WELCOME)}*/

  // {t(T.WELCOME_TO_GRAVITY)}

  return (

    <div className="sign-in-wlc">
      <p className="sign-in-wlc-text sign-in-ellipses">{t(T.WELCOME)}!</p>
       <div className="gravity-logo"/>
      {/*<img src={logo} className="logo-images" alt="#" />*/}
      <p className="sign-in-wlc-desc sign-in-ellipses">{t(T.WELCOME_TO_PASCAL_GRAVITY_BACKOFFICE)}</p>
    </div>
  );
};
