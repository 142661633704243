import {useCallback, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {NAVIGATION_ITEMS, RoutePaths, T} from '../../../../constants';
import {useTranslation} from '../../../../hooks';
import {Button, Icon} from '../../shared';
import {Icons} from '../../../../enums';
import {Permission} from '../../shared';

interface SidebarProps {
	disableMenuList: boolean,
	businessId: number,
}

export const Sidebar: React.FC<SidebarProps> = ({disableMenuList, businessId}) => {

	const [open, setOpen] = useState(false);

	const t = useTranslation();

	const handleClick = useCallback(() => {
		setOpen((prev) => !prev);
	}, []);

	const generatePathById = (path: string, redirectById?: boolean) => {
		return redirectById ? `${path}/${businessId}/edit` : path
	};

	return (
		<div className={`sidebar-holder ${open ? '' : 'closed'}`}>
			<div className="sidebar-button">
				<Button
					size="sm"
					iconSize="xs"
					onClick={handleClick}
					icon={Icons.ArrowLeft}
				/>
			</div>
			<div className="sidebar-holder-inner">
				<div className="sidebar-head-row">
					<NavLink
						to={RoutePaths.Dashboard}
						className="logo-container"
					/>
				</div>
				<div className="sidebar-main-cont">
					<div className="scrollable-container vertical">
						{
							NAVIGATION_ITEMS.map(({path, label, icon, accessedUserTypes, redirectById}) => (
								<Permission
									key={path}
									accessedUserTypes={accessedUserTypes}
								>
									<NavLink
										to={generatePathById(path, redirectById)}
										className={({isActive}) => `${isActive ? 'active' : ''} menu-row ${disableMenuList && label !== T.DASHBOARD ? 'disabled' : ''}`}
									>
										<Icon className="sidebar-icon" type={icon}/>
										<span className="sidebar-text">{t(label)} </span>
									</NavLink>
								</Permission>
							))
						}
					</div>
				</div>
			</div>
		</div>
	);
};
